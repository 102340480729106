import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Icon, Loader, IconTypes } from '../../components';
import { OrderDetail } from '../../containers';
import { fromStore } from '../../selectors';
import { showNotification } from '../../utils';
import { LEXICON } from '../../constants';

export function OrderDetailPage({ isArchive }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const order = useSelector(fromStore.getOrderById(id));
  const loader = useSelector(fromStore.userLoaderSelector);
  const loaderOrders = useSelector(fromStore.userOrdersLoaderSelector);
  const { search } = useLocation();

  useEffect(() => {
    if (isArchive) return;
    if (order && search === '?payment=online' && order.status === 'new') {
      const paymentData = order.paymentData ? JSON.parse(order.paymentData) : null;

      if (paymentData && paymentData.status === 'succeeded') {
        showNotification({
          title: `Заказ №${order.id} оформлен`,
          text: LEXICON.inWorkTextOrder,
          icon: 'success',
        });
      }
      if (paymentData && paymentData.status === 'pending') {
        showNotification({
          title: `Заказ №${order.id} оформлен`,
          text: LEXICON.notPay,
          icon: 'success',
          confirmButtonText: 'Оплатить заказ',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-light',
          },
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            const link = paymentData.confirmation.confirmation_url;
            window.location.replace(link);
          }
        });
      }
    }
  }, [order, isArchive]);

  const goBack = () => {
    navigate(isArchive ? '/archive' : '/orders');
  };

  if (loader || loaderOrders) return <Loader list />;

  if (!order) {
    return (
      <div className="fw-bold">
        <div className="text-gray-700 fs-3 mb-2">Заказ не найден</div>
      </div>
    );
  }

  const title = (
    <div className="toolbar d-flex flex-stack flex-wrap pb-5 pb-lg-5 border-bottom">
      <div className="page-title d-flex flex-column py-1">
        <h1 className="d-flex align-items-center my-1">
          <span className="text-dark fw-bolder fs-1">
            {`Заказ #${id}`}
          </span>
        </h1>
      </div>
      <div onClick={goBack} aria-hidden className="btn btn-light btn-sm btn-hover-scale">
        <Icon className="svg-icon svg-icon-muted svg-icon-1" type={IconTypes.back} />
        Назад
      </div>
    </div>
  );

  return (
    <>
      {title}
      <OrderDetail order={order} />
    </>
  );
}

OrderDetailPage.defaultProps = {
  isArchive: false,
};

OrderDetailPage.propTypes = {
  isArchive: PropTypes.bool,
};
