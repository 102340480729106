import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getIdGood, numberDevider } from '../../../utils';
import { setActiveModalAction } from '../../../actions';

function UnitItem({ count }) {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px">
            <span className="symbol-label" style={{ backgroundImage: 'url("/assets/media/unit.jpg")' }} />
          </div>
          <div className="ms-5">
            <div className="fw-bolder text-gray-600 text-hover-primary">Приборы</div>
            <div className="fs-7 text-muted" />
          </div>
        </div>
      </td>
      <td className="text-end">{count}</td>
      <td className="text-end">0 ₽</td>
      <td className="text-end">0 ₽</td>
    </tr>
  );
}

UnitItem.propTypes = {
  count: PropTypes.string.isRequired,
};

function WokItem({ good }) {
  const {
    main, souce, meat, topping,
  } = good.wokData;
  const [firstSouce, secondSouce] = Object.values(souce);
  const title = `${main.title} + Овощи + Соус ${firstSouce.title}`;

  const getTopping = (toppings) => Object.values(toppings).map((item) => `${item.data.title.toLowerCase()} (${item.count})`).join(', ');

  const getSubtitle = () => {
    const result = [];
    if (secondSouce) {
      result.push(`доп. соус ${secondSouce.title.toLowerCase()}`);
    }
    if (!isEmpty(meat)) {
      result.push(getTopping(meat));
    }
    if (!isEmpty(topping)) {
      result.push(getTopping(topping));
    }
    return result.join(', ');
  };

  const subtitle = getSubtitle();

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px">
            <span className="symbol-label bg-white" style={{ backgroundImage: 'url("/assets/media/wokk.png")' }} />
          </div>
          <div className="ms-5">
            <div className="fw-bolder text-gray-600 text-hover-primary">{title}</div>
            {subtitle && <div className="fs-7 text-muted">{`Добавки: ${subtitle}`}</div>}
          </div>
        </div>
      </td>
      <td className="text-end">{good.count}</td>
      <td className="text-end">{`${good.price} ₽`}</td>
      <td className="text-end">{`${numberDevider(good.price * good.count)} ₽`}</td>
    </tr>
  );
}

WokItem.propTypes = {
  good: PropTypes.object.isRequired,
};

export function GoodItem({
  good, unit, hasInList,
}) {
  const dispatch = useDispatch();
  if (good.isWok) return <WokItem good={good} />;
  if (unit) return <UnitItem count={unit} />;

  const onClickDetail = () => {
    if (hasInList) {
      const id = getIdGood(good.id);
      dispatch(setActiveModalAction({ field: 'detail', value: id }));
    }
  };

  const image = encodeURI(good.data.image);

  const renderCategory = () => {
    if (good.categoryName) {
      return (
        <div className="fs-7 text-muted">
          {good.categoryName}
          {good.categoryName.toLowerCase() === 'пицца' && <span className="badge badge-dark badge-sm ms-2">{`${good.isMod ? '36' : '30'} см`}</span>}
        </div>
      );
    }
    return null;
  };

  const onErrorImage = (e) => {
    e.target.src = '/assets/media/rsz_noimg.jpg';
  };

  return (
    <tr>
      <td>
        <div aria-hidden onClick={onClickDetail} className="d-flex align-items-center cursor-pointer">
          <div>
            <img className="w-50px h-50px rounded" src={image} onError={onErrorImage} alt={good.title} />
          </div>
          <div className="ms-5">
            <div className="fw-bolder text-gray-600 text-hover-primary">{good.data.title}</div>
            {renderCategory()}
          </div>
        </div>
      </td>
      <td className="text-end">{good.count}</td>
      <td className="text-end">{`${good.price} ₽`}</td>
      <td className="text-end">{`${numberDevider(good.price * good.count)} ₽`}</td>
    </tr>
  );
}

GoodItem.defaultProps = {
  good: {},
  unit: null,
  hasInList: false,
};

GoodItem.propTypes = {
  good: PropTypes.object,
  unit: PropTypes.string,
  hasInList: PropTypes.bool,
};
