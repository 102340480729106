import React from 'react';
import PropTypes from 'prop-types';

export function SelectToppings({
  title, items, selected, onSelect,
}) {
  const onSelectMeat = (item) => () => {
    if (selected[item.id]) {
      onSelect({
        ...selected,
        [item.id]: {
          ...selected[item.id],
          count: selected[item.id].count + 1,
        },
      });
    } else {
      onSelect({ ...selected, [item.id]: { ...item, count: 1 } });
    }
  };

  const renderItem = (item) => (
    <div aria-hidden key={item.id} onClick={onSelectMeat(item)}>
      <div className="btn btn-sm btn-light btn-active-secondary w-100 py-2 px-5">
        <span className="fw-bold fs-6">{`${item.title} - ${item.price} ₽`}</span>
      </div>
    </div>
  );

  return (
    <div className="mb-8 mt-0">
      <div className="d-flex align-items-center form-label mb-3">
        {title}
        <i
          className="fas fa-exclamation-circle ms-2 fs-7"
          data-tip="Добавьте необходимое количество добавок"
        />
      </div>
      <div className="d-flex gap-2 flex-wrap">
        {items.map(renderItem)}
      </div>
    </div>
  );
}

SelectToppings.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
