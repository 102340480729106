import {
  takeLatest, select, put, call,
} from 'redux-saga/effects';
import hash from 'object-hash';
import { isEmpty, omit } from 'lodash';
import dayjs from 'dayjs';
import {
  addAdressAction,
  cancelOrder,
  clearCart,
  createOrder,
  fetchOrderPricesPromocode,
  fetchUser,
  loaderAction, setActiveModalAction,
  setOrderDeliveryAdress, setOrderDeliveryZone, setOrderPricesPromocodeData,
} from '../actions';
import { fromStore, orderSelector, settingsSelector } from '../selectors';
import {
  getIdGood,
  getImageUrl,
  getTimeWork, regexpPhone, request, showNotification,
  isModGood,
  getGoodUrl,
} from '../utils';
import { LEXICON, restUrls, host } from '../constants';
import { updateCurrentUser } from './auth';

function* getDeliveryZone(data) {
  const city = yield select(fromStore.selectedCitySelector);
  const settings = yield select(settingsSelector);
  if (!settings.zone) return null;

  const token = hash({
    city: city.name, street: data.street, house: data.house, forZone: 'YM~qrgd1Ea*t',
  });

  const requestData = {
    adress: {
      city: {
        id: city.id,
        name: city.name,
      },
      street: data.street,
      house: data.house,
      area: data.area,
    },
    token,
  };

  const result = yield call(request, { method: 'post', url: `${restUrls.zone}/getZone`, data: requestData }, false);
  let zone = result.error || String(result.id);
  if (zone === 'NOT_ZONES_CITY' || zone === 'COORD_FETCH_ERROR') {
    zone = 'DEFAULT';
  }
  if (zone === 'COORD_NOT_FOUND') {
    const mode = settings.zoneMode === 'abort';
    if (mode) {
      zone = 'NOT_SUPPORTED_ADRESS';
    } else {
      zone = 'DEFAULT';
    }
  }

  return zone;
}

function* handlerAddAdress({ payload: data }) {
  yield put(loaderAction({ field: 'adress', value: true }));
  const user = yield select(fromStore.userSelector);
  const zone = yield getDeliveryZone(data);

  if (user) {
    const updatedData = data.privateHouse ? {
      ...data,
      room: null,
      entrance: null,
      floor: null,
      zone,
    } : { ...data, zone };
    yield updateCurrentUser(updatedData);
  } else {
    yield put(setOrderDeliveryAdress(data));
    yield put(setOrderDeliveryZone(zone));
  }
  yield put(loaderAction({ field: 'adress', value: false }));
  yield put(setActiveModalAction({ field: 'adress', value: false }));
}

const promocodeAdapter = (promocode) => {
  const data = omit(promocode, ['city', 'updated_at', 'created_at']);
  data.cities = data.cities.map((item) => item.id);
  data.goods = isEmpty(data.goods)
    ? {}
    : data.goods.reduce((acc, item) => ({ ...acc, [item.value]: item }), {});
  return data;
};

function* fetchPromocodeHandler({ payload: promocode }) {
  yield put(loaderAction({ field: 'promocode', value: true }));
  const result = yield call(request, { method: 'get', url: restUrls.promocodes, params: { name: promocode.toLowerCase() } });
  if (isEmpty(result)) {
    yield put(setOrderPricesPromocodeData({ error: 'Этого промокода не существует' }));
  } else {
    const promocodeData = promocodeAdapter(result[0]);
    yield put(setOrderPricesPromocodeData(promocodeData));
  }
  yield put(loaderAction({ field: 'promocode', value: false }));
}

// create order

function* checkMinPriceOrder() {
  const deliveryMethod = yield select(fromStore.orderDeliveryMethodSelector);
  const city = yield select(fromStore.selectedCitySelector);
  const zone = yield select(fromStore.orderDeliveryZoneDataSelector);
  const totalCost = yield select(fromStore.totalPriceOrder);

  if (deliveryMethod === 'own') return true;

  const minPrice = zone && zone.minDeliveryOrder
    ? zone.minDeliveryOrder : parseInt(city.minOrder, 10);

  if (minPrice > totalCost) {
    yield put(loaderAction({ field: 'createOrder', value: false }));
    yield showNotification({
      text: `Минимальная сумма заказа ${minPrice} ₽`,
    });
    return false;
  }

  return true;
}

function* checkTimeWork() {
  const deliveryMethod = yield select(fromStore.orderDeliveryMethodSelector);
  const timeMethod = yield select(fromStore.orderDeliveryTimeMethodSelector);
  const selectedTime = yield select(fromStore.orderDeliveryTimeSelector);

  const timeWork = getTimeWork({
    delivery: deliveryMethod,
    timeMethod,
    selectedTime,
  });

  if (!timeWork.isWork) {
    yield put(loaderAction({ field: 'createOrder', value: false }));
    yield showNotification({
      text: timeWork.message,
    });
    return false;
  }
  return true;
}

const getBonus = (totalPrice, bonusConfigs, level, value) => {
  if (!bonusConfigs[level]) return null;

  const result = Math.round(totalPrice * (bonusConfigs[value] / 100));
  if (result === 0) return null;
  return result;
};

function* getCashBack() {
  const totalPrice = yield select(fromStore.totalPriceOrder);
  const { bonusProgramm, bonusConfigs } = yield select(settingsSelector);
  const user = yield select(fromStore.userSelector);

  let cashback = null;
  if (bonusProgramm && user) {
    cashback = {
      level1: getBonus(totalPrice, bonusConfigs, 'level1Active', 'level1Value'),
      level2: getBonus(totalPrice, bonusConfigs, 'level2Active', 'level2Value'),
      level3: getBonus(totalPrice, bonusConfigs, 'level3Active', 'level3Value'),
    };
  }
  return cashback;
}

const souceAdapter = (items) => items.reduce((acc, item) => {
  const result = { ...acc };
  result[item.id] = { ...item };
  return result;
}, {});

const toppingAdapter = (items) => Object.values(items).reduce((acc, item) => {
  const result = { ...acc };
  result[item.id] = {
    count: item.count || 1,
    article: item.article,
    data: {
      id: item.id,
      title: item.title,
      price: item.price,
    },
  };
  return result;
}, {});

const wokItemAdapter = (item) => ({
  id: item.id,
  isMod: false,
  count: item.count,
  isWok: true,
  wokData: {
    id: item.id,
    main: item.main,
    price: item.price,
    meat: toppingAdapter(item.meat),
    topping: toppingAdapter(item.toppings),
    souce: souceAdapter(item.souce),
  },
  price: item.price,
  data: null,
});

function* createNewOrder() {
  const order = yield select(orderSelector);
  const city = yield select(fromStore.selectedCitySelector);
  const user = yield select(fromStore.userSelector);
  const settings = yield select(settingsSelector);
  const cartGoods = yield select(fromStore.cartGoodsSelector);
  const deliveryPrice = yield select(fromStore.deliveryPriceSelector);
  const totalPrice = yield select(fromStore.totalPriceOrder);
  const goodsPrice = yield select(fromStore.totalGoodsPriceSelector);
  const salePrice = yield select(fromStore.allSalePriceOrder);
  const sale = yield select(fromStore.saleTypeSelector);
  const gift = yield select(fromStore.giftCartSelector);
  const woks = yield select(fromStore.wokListCartSelector);
  const cashback = yield getCashBack();
  const phone = regexpPhone(order.customer.phone);
  const { delivery, payment, prices } = order;
  const {
    method, timeMethod, deliveryTime, zoneData, point, adress,
  } = delivery;

  const { bonus, promocodeDate } = prices;

  const goods = cartGoods.filter((item) => !item.isStop).map((item) => {
    const id = getIdGood(item.id);
    const isMod = isModGood(item.id) && !item.isDefault;

    return {
      id: isMod ? item.id : id,
      article: item.article,
      isMod,
      count: item.count,
      isWok: false,
      wokData: null,
      price: item.price,
      category: item.category.id,
      categoryName: item.category.title,
      data: {
        link: getGoodUrl(item.category.key, host),
        image: getImageUrl(item.image, host),
        title: item.name,
        weight: item.weight,
      },
    };
  });

  const woksList = Object.values(woks).map(wokItemAdapter);

  const newOrder = {
    deliveryMethod: method,
    city: city.id,
    user: user ? user.id : null,
    phone,
    comment: order.message,
    timeMethod,
    deliveryTime: timeMethod !== 'fast' ? deliveryTime : null,
    deliveryTimeComment: timeMethod !== 'fast' ? `Время доставки: ${dayjs(deliveryTime).format('DD.MM.YYYY HH:mm')}` : null,
    userName: order.customer.name,
    deliveryPrice,
    totalPrice,
    bonusCount: bonus,
    goodsPrice,
    salePrice,
    unit: order.customer.unit,
    goods: [...goods, ...woksList],
    gifts: gift ? { id: gift.id } : null,
    sale,
    promocode: promocodeDate ? promocodeDate.id : null,
    cashback,
    payment: payment.method,
    email: payment.email || undefined,
    changeCash: payment.method === 'cash' ? payment.changeCash : null,
    zone: settings.zone && method === 'delivery' && zoneData ? zoneData : null,
    source: 'site',
    newApi: true,
    isSite: true,
  };

  if (method === 'own') {
    newOrder.point = point.id;
  } else {
    newOrder.privateHouse = adress.privateHouse;
    newOrder.street = adress.street;
    newOrder.house = adress.house;
    newOrder.area = adress.area;
    if (!adress.privateHouse) {
      newOrder.room = adress.room;
      newOrder.entrance = adress.entrance;
      newOrder.floor = adress.floor;
    }
  }

  return newOrder;
}

function* createOrderHandler({ payload: navigate }) {
  yield put(loaderAction({ field: 'createOrder', value: true }));

  const user = yield select(fromStore.userSelector);

  const isMinPrice = yield checkMinPriceOrder();
  if (!isMinPrice) return;

  const isTimeWork = yield checkTimeWork();
  if (!isTimeWork) return;

  const order = yield createNewOrder();

  const result = yield call(
    request,
    { method: 'post', url: restUrls.orders, data: order },
    false,
    { title: 'Ошибка оформления заказа', allowOutsideClick: true },
    true,
  );

  if (result.error === 'needRefresh') {
    yield put(createOrder(navigate));
    yield put(loaderAction({ field: 'createOrder', value: false }));
    return;
  }

  if (result.id) {
    if (result.paymentId) {
      const paymentData = JSON.parse(result.paymentData);
      if (paymentData && paymentData.confirmation) {
        const urlRedirect = paymentData.confirmation.confirmation_url;
        window.location.replace(urlRedirect);
        yield put(clearCart());
        yield put(cancelOrder());
      }
      return;
    }

    if (user) {
      yield put(fetchUser());
      yield navigate(`/orders/${result.id}`);
    } else {
      yield navigate('/menu');
    }
    yield put(clearCart());
    yield put(cancelOrder());
    yield showNotification({
      title: `Заказ №${result.id} оформлен`,
      text: result.zone ? LEXICON.inWorkTextOrder : LEXICON.confirmTextOrder,
      icon: 'success',
    });
  }
  yield put(loaderAction({ field: 'createOrder', value: false }));
}

export default [
  takeLatest(addAdressAction, handlerAddAdress),
  takeLatest(fetchOrderPricesPromocode, fetchPromocodeHandler),
  takeLatest(createOrder, createOrderHandler),
];
