import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchZone } from '../../../../actions';
import { Icon, IconTypes } from '../../../../components';
import { fromStore, settingsSelector } from '../../../../selectors';

export function DeliveryZone() {
  const dispatch = useDispatch();
  const city = useSelector(fromStore.selectedCitySelector);
  const settings = useSelector(settingsSelector);
  const zoneId = useSelector(fromStore.orderDeliveryZoneSelector);
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const zoneData = useSelector(fromStore.orderDeliveryZoneDataSelector);
  const loader = useSelector(fromStore.zoneLoaderSelector);

  useEffect(() => {
    if (settings.zone && zoneId && deliveryMethod === 'delivery') {
      if (!zoneData || (zoneData && String(zoneData.id) !== zoneId)) {
        if (zoneId !== 'DEFAULT' && zoneId !== 'NOT_SUPPORTED_ADRESS') {
          dispatch(fetchZone(zoneId));
        }
      }
    }
  }, [zoneId, deliveryMethod]);

  const renderDeliveryInfo = (minOrderPrice, deliveryPrice, deliveryTime, freeDelivery) => (
    <div className="mb-0">
      <div className="d-flex flex-stack mb-3">
        <span className="d-flex align-items-center me-2">
          <span className="symbol symbol-40px me-6">
            <span className="symbol-label bg-light">
              <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                <Icon type={IconTypes.cart} />
              </span>
            </span>
          </span>
          <span className="d-flex flex-column">
            <span className="fw-bold text-gray-800 text-hover-primary fs-5">Минимальная сумма заказа</span>
          </span>
        </span>
        <span className="form-check form-check-custom form-check-solid">
          {minOrderPrice}
        </span>
      </div>

      <div className="d-flex flex-stack mb-3">
        <span className="d-flex align-items-center me-2">
          <span className="symbol symbol-40px me-6">
            <span className="symbol-label bg-light">
              <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                <Icon type={IconTypes.delivery} />
              </span>
            </span>
          </span>
          <span className="d-flex flex-column">
            <span className="fw-bold text-gray-800 text-hover-primary fs-5">Стоимость доставки</span>
          </span>
        </span>
        <span className="form-check form-check-custom form-check-solid">
          {deliveryPrice}
        </span>
      </div>

      {
        freeDelivery && (
          <div className="d-flex flex-stack mb-3">
            <span className="d-flex align-items-center me-2">
              <span className="symbol symbol-40px me-6">
                <span className="symbol-label bg-light">
                  <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                    <Icon type={IconTypes.delivery} />
                  </span>
                </span>
              </span>
              <span className="d-flex flex-column">
                <span className="fw-bold text-gray-800 text-hover-primary fs-5">Бесплатная доставка от</span>
              </span>
            </span>
            <span className="form-check form-check-custom form-check-solid">
              {freeDelivery}
            </span>
          </div>
        )
      }

      <div className="d-flex flex-stack mb-0">
        <span className="d-flex align-items-center me-2">
          <span className="symbol symbol-40px me-6">
            <span className="symbol-label bg-light">
              <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                <Icon type={IconTypes.clock} />
              </span>
            </span>
          </span>
          <span className="d-flex flex-column">
            <span className="fw-bold text-gray-800 text-hover-primary fs-5">Время доставки</span>
          </span>
        </span>
        <span className="form-check form-check-custom form-check-solid">
          {deliveryTime}
        </span>
      </div>

    </div>
  );

  if (loader) {
    return (
      <div className="mb-0 mt-7">
        <div className="spinner-border text-danger spinner-border-sm me-2" />
        Расчет стоимости доставки...
      </div>
    );
  }

  if (!zoneId || zoneId === 'DEFAULT' || zoneId === 'NOT_SUPPORTED_ADRESS') {
    if (zoneId === 'NOT_SUPPORTED_ADRESS') {
      return (
        <div className="mb-0 mt-7">
          <div className="alert alert-danger d-flex align-items-center p-5">
            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
              <Icon type={IconTypes.error} />
            </span>
            <div className="d-flex flex-column">
              <span>Адрес вне зоны обслуживания</span>
            </div>
          </div>
        </div>
      );
    }

    const cityDeliveryPrice = +city.deliveryCost ? `${city.deliveryCost} ₽` : 'Бесплатная доставка';
    const cityMinOrderPrice = `${city.minOrder} ₽`;
    const freeDelivery = city.freeDelivery && +city.deliveryCost > 0 ? `${city.freeDelivery} ₽` : '';

    return (
      <div className="mb-0 mt-7">
        {renderDeliveryInfo(
          cityMinOrderPrice,
          cityDeliveryPrice,
          city.deliveryTime,
          freeDelivery,
        )}
      </div>
    );
  }

  if (!zoneData) return null;

  const renderStopPoint = () => {
    if (zoneData.point.activate === 'active' || zoneData.point.activate === 'disabledOwn') return null;

    return (
      <div className="alert alert-danger d-flex align-items-center p-5 mt-10">
        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
          <Icon type={IconTypes.error} />
        </span>
        <div className="d-flex flex-column">
          <span>По техническим причинам доставка временно недоступна</span>
        </div>
      </div>
    );
  };

  const {
    deliveryPrice,
    deliveryTime,
    minDeliveryOrder,
    point,
    minDeliveryPrice,
  } = zoneData;
  const minOrderPrice = `${minDeliveryOrder} ₽`;
  const time = `до ${+deliveryTime + (point?.minDeliveryTime || 0)} мин`;
  const price = deliveryPrice ? `${deliveryPrice} ₽` : 'Бесплатная доставка';
  const freeDelivery = minDeliveryPrice && +deliveryPrice > 0 ? `${minDeliveryPrice} ₽` : '';

  return (
    <div className="mb-0 mt-7">
      {renderDeliveryInfo(minOrderPrice, price, time, freeDelivery)}
      {renderStopPoint()}
    </div>
  );
}
