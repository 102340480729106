import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../../selectors';
import { numberDevider } from '../../../../utils';
import { ORDER_VALUE_MAPS } from '../../../../constants';

export function GoodsPrice() {
  const bonus = useSelector(fromStore.orderPricesBonusSelector);
  const сostGoods = useSelector(fromStore.totalGoodsPriceWithSaleSelector);
  const isActive = useSelector(fromStore.isActivePromocodeSelector);
  const totalCost = useSelector(fromStore.totalPriceOrder);
  const salePromocode = useSelector(fromStore.salePromocodeSelector);
  const promocode = useSelector(fromStore.orderPricesPromocodeSelector);
  const promocodeData = useSelector(fromStore.orderPricesPromocodeDataSelector);
  const deliveryPrice = useSelector(fromStore.deliveryPriceSelector);
  const deliverMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const firstOrderSale = useSelector(fromStore.saleFirstOrderSelector);
  const bonusForUser = useSelector(fromStore.bonusForUserSelector);
  const paymentMethod = useSelector(fromStore.orderPaymentMethodSelector);

  return (
    <>
      <tr>
        <td colSpan="3" className="text-end py-3">Сумма заказа</td>
        <td className="text-end">{`${numberDevider(сostGoods)} ₽`}</td>
      </tr>

      {deliverMethod === 'delivery' && (
        <tr>
          <td colSpan="3" className="text-end py-3">Доставка</td>
          <td className="text-end">{deliveryPrice > 0 ? `${numberDevider(deliveryPrice)} ₽` : 'Бесплатно'}</td>
        </tr>
      )}

      {
        firstOrderSale > 0 && (
          <tr>
            <td colSpan="3" className="text-end py-3">Скидка на первый заказ</td>
            <td className="text-end">{`- ${numberDevider(firstOrderSale)} ₽`}</td>
          </tr>
        )
      }

      {isActive && promocodeData.type === 'order' && (
      <tr>
        <td colSpan="3" className="text-end py-3">
          Скидка по промокоду
          {' '}
          <span className="badge badge-info badge-sm ms-2">{promocode}</span>
        </td>
        <td className="text-end">{`- ${numberDevider(salePromocode)} ₽`}</td>
      </tr>
      )}

      {bonus > 0 && (
        <tr>
          <td colSpan="3" className="text-end py-3">Бонусы</td>
          <td className="text-end">{`- ${numberDevider(bonus)} ₽`}</td>
        </tr>
      )}

      <tr>
        <td colSpan="3" className="text-end py-3">Способ оплаты</td>
        <td className="text-end">{ORDER_VALUE_MAPS.payment[paymentMethod]}</td>
      </tr>

      <tr>
        <td colSpan="3" className="fs-3 text-dark text-end py-3">К оплате</td>
        <td className="text-dark fs-3 fw-boldest text-end">{`${numberDevider(totalCost)} ₽`}</td>
      </tr>
      {
        bonusForUser > 0 && (
          <tr>
            <td colSpan="3" className="text-end pt-5">Бонусы к зачислению</td>
            <td className="text-end pt-5"><span className="badge badge-success">{`${bonusForUser} Б.`}</span></td>
          </tr>
        )
      }
    </>
  );
}
