import React from 'react';
import PropTypes from 'prop-types';
import { OrdersList } from '../../containers/orders';

export function OrdersListPage({ isArchive }) {
  const title = (
    <div className="toolbar d-flex flex-stack flex-wrap pb-5 pb-lg-5 border-bottom">
      <div className="page-title d-flex flex-column py-1">
        <h1 className="d-flex align-items-center my-1">
          <span className="text-dark fw-bolder fs-1">{isArchive ? 'Архив заказов' : 'Мои заказы'}</span>
        </h1>
      </div>
    </div>
  );

  return (
    <>
      {title}
      <OrdersList isArchive={isArchive} />
    </>
  );
}

OrdersListPage.defaultProps = {
  isArchive: false,
};

OrdersListPage.propTypes = {
  isArchive: PropTypes.bool,
};
