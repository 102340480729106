/* eslint-disable no-unused-vars */
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty } from 'lodash';
import { orderSelector, settingsSelector } from './root';
import { getIdGood, propNameSelector } from '../utils';
import { cartGoodsSelector } from './goods';
import { selectedCitySelector } from './cities';
import { cartListSelector, wokListCartSelector } from './cart';
import { userSelector } from './auth';

dayjs.extend(isBetween);

export const orderMessageSelector = propNameSelector(orderSelector, 'message');
export const orderStepSelector = propNameSelector(orderSelector, 'step');
export const orderDeliverySelector = propNameSelector(orderSelector, 'delivery');
export const orderPricesSelector = propNameSelector(orderSelector, 'prices');
export const orderCustomerSelector = propNameSelector(orderSelector, 'customer');
export const orderErrorsSelector = propNameSelector(orderSelector, 'errors');
export const orderPaymentSelector = propNameSelector(orderSelector, 'payment');

export const orderDeliveryCitySelector = propNameSelector(orderDeliverySelector, 'city');
export const orderDeliveryAdressSelector = propNameSelector(orderDeliverySelector, 'adress');
export const orderDeliveryMethodSelector = propNameSelector(orderDeliverySelector, 'method');
export const orderDeliveryPointSelector = propNameSelector(orderDeliverySelector, 'point');
export const orderDeliveryZoneSelector = propNameSelector(orderDeliverySelector, 'zone');
export const orderDeliveryZoneDataSelector = propNameSelector(orderDeliverySelector, 'zoneData');
export const orderDeliveryTimeMethodSelector = propNameSelector(orderDeliverySelector, 'timeMethod');
export const orderDeliveryTimeSelector = propNameSelector(orderDeliverySelector, 'deliveryTime');

export const orderCustomerNameSelector = propNameSelector(orderCustomerSelector, 'name');
export const orderPhoneSelector = propNameSelector(orderCustomerSelector, 'phone');
export const orderUnitSelector = propNameSelector(orderCustomerSelector, 'unit');

export const orderPricesBonusSelector = propNameSelector(orderPricesSelector, 'bonus');
export const orderPricesPromocodeSelector = propNameSelector(orderPricesSelector, 'promocode');
export const orderPricesPromocodeDataSelector = propNameSelector(orderPricesSelector, 'promocodeData');

export const orderPaymentMethodSelector = propNameSelector(orderPaymentSelector, 'method');
export const orderPaymentCashSelector = propNameSelector(orderPaymentSelector, 'changeCash');
export const orderPaymentEmailSelector = propNameSelector(orderPaymentSelector, 'email');

export const totalGoodsPriceSelector = createSelector(
  cartGoodsSelector,
  cartListSelector,
  wokListCartSelector,
  (goods, counts, woks) => {
    const goodsPrice = goods.reduce((acc, item) => {
      const price = +item.price;
      const count = counts[item.id];
      const total = price * count;
      return acc + total;
    }, 0);
    const woksPrice = Object.values(woks).reduce((acc, item) => acc + (item.price * item.count), 0);
    return goodsPrice + woksPrice;
  },
);

export const checkPromocode = createSelector(
  orderPricesPromocodeDataSelector,
  totalGoodsPriceSelector,
  orderDeliveryMethodSelector,
  selectedCitySelector,
  (promocode, goodsPrice, deliveryMethod, city) => {
    if (!promocode) return null;
    if (promocode.error) return promocode.error;
    if (!promocode.active) return 'Данный промокод недействителен';
    if (goodsPrice < +promocode.minPrice) return `Минимальная сумма заказа для активации данного промокода ${promocode.minPrice} ₽`;
    if (promocode.delivery !== 'all' && deliveryMethod !== promocode.delivery) return 'Данный промокод недействителен для данного способа доставки';
    if (!isEmpty(promocode.cities) && !promocode.cities.includes(city.id)) return 'Данный промокод недействителен в этом регионе';
    if (promocode.startDate && !promocode.endDate) {
      if (dayjs(new Date()).isBefore(dayjs(promocode.startDate))) {
        return 'Дата начала промокода не наступила';
      }
    }
    if (!promocode.startDate && promocode.endDate) {
      if (dayjs(new Date()).isAfter(dayjs(promocode.endDate))) {
        return 'Промокод уже недействителен';
      }
    }
    if (promocode.startDate && promocode.endDate) {
      if (!dayjs(new Date()).isBetween(promocode.startDate, promocode.endDate)) {
        return 'Данный промокод недействителен';
      }
    }

    return 'active';
  },
);

export const isActivePromocodeSelector = createSelector(
  checkPromocode,
  (info) => info === 'active',
);

export const errorPromocodeSelector = createSelector(
  checkPromocode,
  (info) => (info !== 'active' ? info : null),
);

export const salePricesGood = createSelector(
  cartGoodsSelector,
  isActivePromocodeSelector,
  orderPricesPromocodeDataSelector,
  (goods, isActivePromocode, promocode) => {
    if (!isActivePromocode) return {};
    if (promocode.type === 'category') {
      return goods
        .filter((item) => {
          const id = +item.category.oldId || +item.category.id;
          const promocodeId = promocode?.category?.value ? +promocode.category.value : null;
          return id === promocodeId;
        })
        .reduce((acc, item) => {
          if (promocode.persent) {
            const price = item.price - Math.round(item.price * (+promocode.value / 100));
            return { ...acc, [item.id]: { price, sale: item.price - price } };
          }
          const price = item.price - +promocode.value;
          return { ...acc, [item.id]: { price, sale: item.price - price } };
        }, {});
    }
    if (promocode.type === 'goods') {
      return goods
        .filter((item) => promocode.goods[getIdGood(item.id, item.article)])
        .reduce((acc, item) => {
          const configPromocode = promocode.goods[getIdGood(item.id, item.article)];
          if (configPromocode.persent) {
            const price = item.price - Math.round(item.price * (+configPromocode.sale / 100));
            return { ...acc, [item.id]: { price, sale: item.price - price } };
          }
          const price = item.price - +configPromocode.sale;
          return { ...acc, [item.id]: { price, sale: item.price - price } };
        }, {});
    }
    return {};
  },
);

export const salePromocodeSelector = createSelector(
  isActivePromocodeSelector,
  orderPricesPromocodeDataSelector,
  totalGoodsPriceSelector,
  cartListSelector,
  salePricesGood,
  (isActive, promocode, priceGoods, counts, sales) => {
    if (!isActive) return 0;
    if (promocode.type === 'order') {
      if (promocode.persent) {
        return Math.round(priceGoods * (+promocode.value / 100));
      }
      return +promocode.value;
    }
    if (promocode.type === 'category' || promocode.type === 'goods') {
      return Object.entries(sales).reduce((acc, [id, value]) => acc + (value.sale * counts[id]), 0);
    }

    return 0;
  },
);

export const deliveryPriceSelector = createSelector(
  orderDeliveryZoneDataSelector,
  selectedCitySelector,
  orderDeliveryMethodSelector,
  totalGoodsPriceSelector,
  (zone, city, deliveryMethod, goodsPrice) => {
    if (deliveryMethod === 'own') return 0;
    const deliveryPrice = zone && zone.deliveryPrice ? zone.deliveryPrice : +city.deliveryCost;
    const freeDelivery = zone && zone.minDeliveryPrice ? zone.minDeliveryPrice : +city.freeDelivery;

    if (goodsPrice < freeDelivery) {
      return deliveryPrice;
    }
    return 0;
  },
);

export const saleFirstOrderSelector = createSelector(
  userSelector,
  settingsSelector,
  totalGoodsPriceSelector,
  (user, { saleFirstOrderActive, saleFirstOrderValue, saleFirstPersent }, totalPrice) => {
    if (!user || !saleFirstOrderActive) return 0;
    if (!isEmpty(user.orders)) return 0;
    if (saleFirstPersent) {
      return Math.round(totalPrice * (+saleFirstOrderValue / 100));
    }
    return +saleFirstOrderValue;
  },
);

export const allSalePriceOrder = createSelector(
  salePromocodeSelector,
  saleFirstOrderSelector,
  (salePromocode, saleFirstOrder) => {
    let total = 0;
    if (saleFirstOrder > 0) {
      total += saleFirstOrder;
    }
    if (salePromocode) {
      total += salePromocode;
    }
    return total;
  },
);

export const totalPriceOrder = createSelector(
  totalGoodsPriceSelector,
  orderPricesBonusSelector,
  salePromocodeSelector,
  deliveryPriceSelector,
  saleFirstOrderSelector,
  (priceGoods, bonus, salePromocode, deliveryPrice, saleFirstOrder) => {
    let total = priceGoods;
    if (bonus) {
      total -= bonus;
    }
    if (saleFirstOrder > 0) {
      total -= saleFirstOrder;
    }
    if (deliveryPrice > 0) {
      total += deliveryPrice;
    }
    if (salePromocode) {
      total -= salePromocode;
    }
    return total;
  },
);

export const totalGoodsPriceWithSaleSelector = createSelector(
  isActivePromocodeSelector,
  orderPricesPromocodeDataSelector,
  totalGoodsPriceSelector,
  salePromocodeSelector,
  (isActivePromocode, promocode, goodsPrice, saleGoods) => {
    if (!isActivePromocode) return goodsPrice;
    if (promocode.type === 'category' || promocode.type === 'goods') return goodsPrice - saleGoods;
    return goodsPrice;
  },
);

export const bonusForUserSelector = createSelector(
  totalPriceOrder,
  settingsSelector,
  userSelector,
  (totalPrice, { bonusProgramm, bonusConfigs }, user) => {
    if (!bonusProgramm || !user) return 0;
    const { level1Active, level1Value } = bonusConfigs;
    if (!level1Active) return 0;
    return Math.round(totalPrice * (level1Value / 100));
  },
);

export const saleTypeSelector = createSelector(
  settingsSelector,
  saleFirstOrderSelector,
  orderPricesPromocodeDataSelector,
  ({ saleFirstOrderValue, saleFirstPersent }, saleFirstOrder, promocode) => {
    if (saleFirstOrder > 0) {
      return ({
        code: 'firstOrder',
        name: 'Скидка на первый заказ',
        data: { value: saleFirstOrderValue, persent: !!saleFirstPersent },
      });
    }

    if (!isEmpty(promocode)) {
      return ({
        code: 'promocode',
        name: `Скидка по промокоду ${promocode.name}`,
        data: {
          name: `Скидка по промокоду ${promocode.name}`,
          value: +promocode.value,
          persent: !!promocode.persent,
          type: promocode.type,
          goods: promocode.goods,
          category: promocode.category,
        },
      });
    }

    return null;
  },
);

export const isActiveGift = createSelector(
  selectedCitySelector,
  totalGoodsPriceSelector,
  (city, totalPrice) => {
    if (!city || !city.gift) return false;
    if (totalPrice < +city.gift) return false;
    return true;
  },
);
