import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EMPTY_TEXT } from '../../../constants';
import { fromStore } from '../../../selectors';
import { sortOrderByDate } from '../../../utils';
import { OrderListRow } from './OrderListRow';
import { Loader } from '../../../components';

export function OrdersList({ isArchive }) {
  const navigate = useNavigate();
  const user = useSelector(fromStore.userSelector);
  const loader = useSelector(fromStore.userOrdersLoaderSelector);
  const orders = user ? user.orders : [];
  const url = isArchive ? '/archive' : '/orders';
  const onNavigate = (id) => () => {
    navigate(`${url}/${id}`);
  };

  const tableHeader = (
    <thead>
      <tr className="fw-bolder text-muted bg-light">
        <th className="ps-4 min-w-100px rounded-start">Номер</th>
        <th className="min-w-200px">Дата</th>
        <th className="min-w-150px">Способ доставки</th>
        <th className="min-w-125px">К оплате</th>
        <th className="min-w-100px">Бонусы</th>
        <th className="min-w-100px">Статус</th>
        <th className="min-w-100px text-end rounded-end">{EMPTY_TEXT}</th>
      </tr>
    </thead>
  );

  const renderList = (
    <div className="table-responsive">
      <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-4">
        {tableHeader}
        <tbody>
          {orders
            .sort(sortOrderByDate)
            .map((order) => <OrderListRow key={`order_${order.id}`} onClick={onNavigate} order={order} />)}
        </tbody>
      </table>
    </div>
  );

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">У вас еще нет заказов</div>
    </div>
  );

  if (loader) return <Loader list />;

  return (
    <div className="post abs">
      <div className="card mb-5 mb-xl-8 border-0">
        <div className="card-body p-0">
          { orders.length ? renderList : emptyResult }
        </div>
      </div>
    </div>
  );
}

OrdersList.defaultProps = {
  isArchive: false,
};

OrdersList.propTypes = {
  isArchive: PropTypes.bool,
};
