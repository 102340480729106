import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';

import {
  loaderAction, authUser, changeAuthStep, setUser,
  logoutAction, updateUser, setAuthError,
  updateUserData,
  updateAdressData,
  setActiveModalAction,
  updateUserPoint,
  cancelOrder,
  authSuccess,
  fetchUser,
  fetchOrdersUser,
} from '../actions';
import { restUrls } from '../constants';
import { fromStore } from '../selectors';
import {
  deleteCookie, getCookie, regexpPhone, request, setCookie, showNotification,
} from '../utils';

export function* fetchUserOrders({ payload: isArchive }) {
  yield put(loaderAction({ field: 'userOrders', value: true }));
  const user = yield select(fromStore.userSelector);
  const orders = yield call(request, { method: 'get', url: isArchive ? restUrls.userArchive : restUrls.userOrders });
  yield put(setUser({ ...user, orders }));
  yield put(loaderAction({ field: 'userOrders', value: false }));
}

export function* fetchCurrentUser() {
  yield put(loaderAction({ field: 'user', value: true }));
  const jwt = yield getCookie('auth');
  if (jwt) {
    const user = yield call(request, { method: 'get', url: restUrls.currentUser });
    yield put(setUser({ ...user, orders: [] }));
    yield put(fetchOrdersUser());
  }
  yield put(loaderAction({ field: 'user', value: false }));
}

export function* updateCurrentUser(data) {
  const user = yield select(fromStore.userSelector);
  const result = yield call(request, { method: 'put', url: `${restUrls.users}/${user.id}`, data });
  yield put(updateUser({ ...result, orders: user.orders || [] }));
}

function* authUserHandler({ payload: { login, code, userCode } }) {
  yield put(loaderAction({ field: 'auth', value: true }));
  yield put(setAuthError(null));

  const phone = regexpPhone(login);
  const result = yield call(request, { method: 'post', url: restUrls.phonecodes, data: { phone, code, userCode } });

  if (result === 'code_send') {
    yield put(changeAuthStep('2'));
  } else if (result.jwt) {
    yield setCookie('auth', result.jwt);
    yield put(setUser({ ...result.user, orders: [] }));
    yield put(fetchOrdersUser());
    yield put(authSuccess());
    yield put(cancelOrder());
    yield showNotification({
      text: 'Номер телефона подтвержден',
      icon: 'success',
    });
  } else if (result === 'error_code') {
    yield put(setAuthError(result));
  }

  yield put(loaderAction({ field: 'auth', value: false }));
}

function* onLogout() {
  yield put(setUser(null));
  yield put(cancelOrder());
  yield deleteCookie('auth');
}

function* onUpdateUserData({ payload: data }) {
  yield put(loaderAction({ field: 'user', value: true }));
  yield updateCurrentUser(data);
  yield put(loaderAction({ field: 'user', value: false }));
}

function* onUpdateUserAdress({ payload: data }) {
  yield put(loaderAction({ field: 'user', value: true }));

  const updatedData = data.privateHouse ? {
    ...data,
    room: null,
    entrance: null,
    floor: null,
  } : { ...data };

  yield updateCurrentUser(updatedData);
  yield put(loaderAction({ field: 'user', value: false }));
  yield put(setActiveModalAction({ field: 'adress', value: false }));
}

function* onUpdateUserPoint({ payload: id }) {
  yield put(loaderAction({ field: 'user', value: true }));
  yield updateCurrentUser({ points: [id] });
  yield put(loaderAction({ field: 'user', value: false }));
  yield put(setActiveModalAction({ field: 'point', value: false }));
}

export default [
  takeLatest(authUser, authUserHandler),
  takeLatest(logoutAction, onLogout),
  takeLatest(updateUserData, onUpdateUserData),
  takeLatest(updateAdressData, onUpdateUserAdress),
  takeLatest(updateUserPoint, onUpdateUserPoint),
  takeLatest(fetchUser, fetchCurrentUser),
  takeLatest(fetchOrdersUser, fetchUserOrders),
];
