import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addWokToCart, deleteWokFromCart } from '../../actions';

export function CartWok({ item }) {
  const dispatch = useDispatch();

  const {
    price, count, main, souce, meat, toppings,
  } = item;

  const handleAddToCart = () => {
    dispatch(addWokToCart(item));
  };

  const handleDeleteFromCart = () => {
    dispatch(deleteWokFromCart(item));
  };

  const renderButtons = (
    <div className="position-relative me-5">
      <button onClick={handleDeleteFromCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
      <input type="text" className="form-control h-35px fs-4 text-center w-125px form-control-solid border-0" readOnly="readonly" value={count} />
      <button onClick={handleAddToCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
    </div>
  );

  const getTitle = () => {
    const title = [main.title, 'овощи'];
    souce.forEach((sou) => title.push(`Соус ${sou.title}`));
    Object.values(meat).forEach((mea) => title.push(`${mea.title} x ${mea.count}`));
    Object.values(toppings).forEach((top) => title.push(`${top.title} x ${top.count}`));
    return title.join(', ').toLocaleLowerCase();
  };

  return (
    <>
      <div className="d-flex flex-stack">
        <div className="d-flex align-items-center me-3">
          <img src="/assets/media/wokk.png" className="me-4 w-50px" alt="" />
          <div className="flex-grow-1">
            <span className="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">Мой Wok</span>
            <span className="text-gray-400 fw-bold d-block fs-7">
              {getTitle()}
            </span>
          </div>
        </div>
        <div className="d-flex flex-stack">
          {renderButtons}
          <span style={{ whiteSpace: 'nowrap' }} className="text-gray-800 fw-bolder fs-6">{`${price} ₽`}</span>
        </div>
      </div>
      <div className="separator separator-dashed my-4" />
    </>
  );
}

CartWok.propTypes = {
  item: PropTypes.object.isRequired,
};
