import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fromStore } from '../../selectors';
import { logoutAction, setActiveModalAction } from '../../actions';
import { declOfNum, formatPhone } from '../../utils';

export function ProfileMenu() {
  const dispatch = useDispatch();
  const ref = useRef();
  const user = useSelector(fromStore.userSelector);

  const openAuth = () => {
    dispatch(setActiveModalAction({ field: 'auth', value: true }));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const onClick = () => {
    ref.current.close();
  };

  if (!user) {
    return (
      <div className="d-flex align-items-center ms-lg-2">
        <div
          aria-hidden
          onClick={openAuth}
          className={cn('btn btn-icon-muted btn-active-light btn-active-color-primary cursor-pointer')}
        >
          <span className="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor" />
              <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor" />
            </svg>
          </span>
          <span className="d-none d-lg-inline-block">Войти</span>
        </div>
      </div>
    );
  }

  const triger = (open) => (
    <div className={cn('btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3 ms-3', { 'show menu-dropdown': open })} aria-hidden="true">
      <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2">
        <span className="text-dark fs-base fw-bolder lh-1 mb-2">{user.name || 'Пользователь'}</span>
        <span className="text-muted fs-7 fw-bold lh-1">
          {declOfNum(user.balance, ['бонус', 'бонуса', 'бонусов'])}
        </span>
      </div>
      <div className="symbol symbol-30px symbol-md-40px">
        <img src="/assets/media/avatars/kp.jpg" alt="avatar" />
      </div>
    </div>
  );

  const content = (
    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px show">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="avatar" src="/assets/media/avatars/kp.jpg" />
          </div>
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.name || 'Пользователь'}
            </div>
            <span className="fw-bold text-muted text-hover-primary fs-7">
              {formatPhone(user.phone)}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5">
        <NavLink to="/profile" onClick={onClick} className="menu-link px-5">Мой профиль</NavLink>
      </div>

      <div className="menu-item px-5">
        <NavLink to="/orders" onClick={onClick} className="menu-link flex-stack px-5">
          <span className="menu-text">Мои заказы</span>
        </NavLink>
      </div>

      <div className="menu-item px-5">
        <NavLink to="/favorites" onClick={onClick} className="menu-link flex-stack px-5">
          <span className="menu-text">Избранное</span>
          <span className="menu-badge">
            <span className="badge badge-secondary fw-bolder fs-7">{user?.favorites?.length || 0}</span>
          </span>
        </NavLink>
      </div>

      <div className="menu-item px-5">
        <NavLink to="/notifications" onClick={onClick} className="menu-link flex-stack px-5">
          <span className="menu-text">Уведомления</span>
        </NavLink>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5">
        <div className="d-flex text-gray-800 py-2 flex-stack px-5">
          <span className="menu-text">
            Баланс
          </span>
          <span className="menu-badge">
            <span className="badge badge-success fw-bolder fs-7">{declOfNum(user.balance, ['бонус', 'бонуса', 'бонусов'])}</span>
          </span>
        </div>
      </div>

      <div className="menu-item px-5">
        <div className="d-flex text-gray-800 py-2 flex-stack px-5">
          <span className="menu-text">
            Бонус-код
          </span>
          <span className="menu-badge">
            <span className="badge badge-dark fw-bolder fs-7">{user.code}</span>
          </span>
        </div>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5">
        <div aria-hidden onClick={logout} className="menu-link px-5">Выход</div>
      </div>
    </div>
  );

  return (
    <div className="d-flex align-items-center ms-lg-2">
      <Popup
        ref={ref}
        trigger={triger}
        position="bottom right"
        closeOnDocumentClick
        offsetY={21}
        arrow={false}
      >
        {content}
      </Popup>
    </div>
  );
}
