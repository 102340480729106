import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconTypes } from '../../../components';

export function ToppingRows({ selectedItems, onDelete }) {
  const deleteItem = (item) => () => {
    const updated = { ...selectedItems };
    delete updated[item.id];
    onDelete(updated);
  };

  const renderItem = (item) => (
    <tr key={item.id}>
      <td>{item.title}</td>
      <td>
        <span className="text-muted me-1">x</span>
        {item.count}
      </td>
      <td className="text-end">{`${item.price * item.count} ₽`}</td>
      <td className="text-end">
        <button onClick={deleteItem(item)} type="button" className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px">
          <Icon type={IconTypes.trash} className="svg-icon svg-icon-3" />
        </button>
      </td>
    </tr>
  );

  return Object.values(selectedItems).map(renderItem);
}

ToppingRows.propTypes = {
  selectedItems: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};
