import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UserPageContent } from '../containers';
import { OrderDetailPage } from './orders/OrderDetailPage';
import { OrdersListPage } from './orders/OrdersListPage';
import { fetchOrdersUser } from '../actions';

export default function OrdersPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrdersUser(true));
  }, []);

  return (
    <UserPageContent>
      <Routes>
        <Route path="/" element={<OrdersListPage isArchive />} />
        <Route path=":id" element={<OrderDetailPage isArchive />} />
      </Routes>
    </UserPageContent>
  );
}
