import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { numberDevider } from '../../utils';

export function GoodPrice({ id, price, oldPrice }) {
  return (
    <div data-tip data-for={`price_${id}`} className="text-end">
      <div className={cn('fs-1 fw-bolder')}>{`${price} ₽`}</div>
      {
          !!oldPrice && (
            <span style={{ top: -5, right: 3 }} className="fs-4 old-price">{`${numberDevider(oldPrice)} ₽`}</span>
          )
        }
    </div>
  );
}

GoodPrice.defaultProps = {
  oldPrice: 0,
};

GoodPrice.propTypes = {
  price: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
  id: PropTypes.string.isRequired,
};
